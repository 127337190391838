/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, Typography } from '@mui/material';
import { useCallback, useContext, useState } from 'react';

import { StatusCode } from '../../../api/enumerations';
import { acceptTermsOfUse } from '../../../api/users';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage } from '../../../helpers';
import { theme } from '../../../styles/theme';
import { StyledDialog } from '../../UI/Dialog';
import { DialogContainer } from '../ConfirmationDialog/styles';

interface LoginUserData {
  email: string;
  id: number;
  name: string | null;
  session_id: number;
}

interface TermsOfUseDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  userData: LoginUserData;
  setAccepted: (value: boolean) => void;
}

const linkStyle = {
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
};
const typographyStyle = {
  fontSize: '18px',
};
const titleTypographyStyle = {
  fontSize: '28px',
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
};

export function TermsOfUseDialog({
  open,
  setOpen,
  userData,
  setAccepted,
}: TermsOfUseDialogProps): JSX.Element {
  const [isSelected, setIsSelected] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const getFirstName = (): string => {
    const firstName = userData.name?.split(' ')[0];
    if (firstName) return firstName;
    return '';
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIsSelected(event.target.checked);
  };

  const submitAcceptTerms = useCallback(async () => {
    try {
      const { detail } = await acceptTermsOfUse(userData.id);

      if (detail.description) {
        throw new Error(detail.description);
      }

      if (detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      setAccepted(true);
      setOpen(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setOpen(false);
    }
  }, []);

  return (
    <StyledDialog open={open} onClose={() => setOpen(false)}>
      <DialogContainer>
        <Typography sx={titleTypographyStyle}>
          Aceite dos Termos de Uso e da Política de Privacidade
        </Typography>
        <Typography variant="body1" sx={typographyStyle}>
          Prezado(a) {getFirstName()}, <br />
          Para continuar utilizando nossos serviços, solicitamos que leiam
          atentamente nossos{' '}
          <a
            target="_blank"
            rel="noreferrer"
            style={linkStyle}
            href="https://marketing.protoai.com.br/LP/termos-de-uso"
          >
            Termos de Uso
          </a>{' '}
          e nossa{' '}
          <a
            target="_blank"
            rel="noreferrer"
            style={linkStyle}
            href="https://marketing.protoai.com.br/LP/politica-de-privacidade"
          >
            Política de Privacidade
          </a>
          .
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            // gap: 2,
            fontSize: '16px',
            color: '#2E383FCC',
          }}
        >
          <Checkbox
            checked={isSelected}
            onChange={handleChange}
            color="primary"
          />
          li e concordo com os termos de uso.
        </Typography>
      </DialogContainer>
      <div
        style={{
          width: '100%',
          paddingTop: '16px',
          paddingBottom: '50px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          sx={{
            width: '403px',
            height: '48px',
            borderRadius: '30px',
            background: '#520679 0% 0% no-repeat padding-box',
          }}
          disabled={!isSelected}
          onClick={submitAcceptTerms}
        >
          continuar
        </Button>
      </div>
    </StyledDialog>
  );
}
